/* Fonts */

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/lato-light.woff2') format('woff2'),
    url('../fonts/lato-light.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/lato-regular.woff2') format('woff2'),
    url('../fonts/lato-regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/lato-regular-italic.woff2') format('woff2'),
    url('../fonts/lato-regular-italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/lato-bold.woff2') format('woff2'),
    url('../fonts/lato-bold.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/lato-bold-italic.woff2') format('woff2'),
    url('../fonts/lato-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/josefinsans-semibold.woff2') format('woff2'),
    url('../fonts/josefinsans-semibold.woff') format('woff');
}

/* App */

.hm {
  font-size: 16px;
  color: #000;
  font-family: 'Lato', sans-serif;
}

.hm .hm-dark {
  color: #ffffff;
}

.hm .hm-logo-title {
  color: white;
  font-size: 3em;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
}

.hm .hm-logo-small-title {
  color: white;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 2.1px;
}

/* Layout */
.hm .hm-no-padding {
  padding: 0;
}

.hm .hm-no-margin {
  margin: 0;
}
.hm .hm-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hm .hm-aligner-item {
  flex: 1;
}

.hm .hm-aligner-item-top {
  align-self: flex-start;
}

.hm .hm-aligner-item-bottom {
  align-self: flex-end;
}

.hm .hm-aligner-item-fixed {
  flex: none;
  max-width: 50%;
}

.hm .row {
  margin-left: 0;
  margin-right: 0;
}

/* Text */

.hm h1 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.7em;
  margin: 0;
}

.hm .hm-dark h1 {
  color: #ffffff;
}

.hm h2 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1.15em;
  line-height: 1.5em;
  margin: 0;
}

.hm .hm-dark h2 {
  color: #ffffff;
}

.hm .hm-subtitle {
  font-family: 'Lato';
  font-size: 0.875em;
  line-height: 1.14;
}

.hm .hm-dark .hm-subtitle {
  color: rgba(255, 255, 255, 0.4);
}

.hm .hm-link-title {
  font-family: 'Lato';
  font-size: 1em;
}

.hm .hm-light .hm-link-title {
  color: #333f48;
}

.hm .hm-dark .hm-link-title {
  color: #ffb300;
}

.hm .hm-dark.hm-dark-blue .hm-link-title {
  color: #a2bae4;
}

.hm .hm-link-subtitle {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 0.625em;
  line-height: 1.6;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

.hm .hm-light .hm-link-subtitle {
  color: #456292;
}

.hm .hm-dark .hm-link-subtitle {
  opacity: 0.5;
  color: #ffffff;
}

.hm .hm-card-title {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 1.1875em;
  letter-spacing: 0.11875em;
  text-align: center;
  text-transform: uppercase;
}

.hm .hm-dark .hm-card-title {
  color: #ffffff;
}

.hm .hm-card-subtitle {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 0.75em;
  letter-spacing: 0.06316em;
  line-height: 1.33;
  text-align: center;
  text-transform: uppercase;
}

.hm .hm-dark .hm-card-subtitle {
  color: #ffffff;
  opacity: 0.5;
}

.hm .hm-card-figure {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 2em;
  text-align: center;
}

.hm .hm-dark .hm-card-figure {
  color: #ffffff;
}

.hm .hm-card-body {
  font-family: 'Lato';
  font-size: 0.875em;
  line-height: 1.14;
}

.hm .hm-dark .hm-card-body {
  color: #ffffff;
  opacity: 0.4;
}

.hm .hm-flex-column-container,
.hm .hm-tabs,
.hm .hm-tab-content,
.hm .hm-tab-content > div,
.hm .hm-tab-content > div > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* For users with motion issues or devices that struggle with animations */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  /* Remove duration for all unknown animation when a user requests a reduced animation experience */
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}