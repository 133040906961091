/* Disables yellow highlighting of autofill inputs in webkit browsers */

.hm-light input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px white;
}

.hm-dark input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px #1d252d;
  -webkit-text-fill-color: #ffffff !important;
}

.hm-form-holder {
  overflow: hidden;
}

.hm-light .hm-form-holder {
  background-color: #ffffff;
}

.hm-dark .hm-form-holder {
  background-color: #1d252d;
}
