.hm-markdown table {
  margin-top: 1.5em;
  border-collapse: collapse;
  width: 100%;
}

.hm-markdown  th, td {
  text-align: left;
  padding: 0.9em;
}

.hm-light .hm-markdown tr:nth-child(even){
  background-color: rgba(55, 65, 72, 0.1)
}

.hm-dark .hm-markdown tr:nth-child(even){
  background-color: rgba(255, 255, 255, 0.1)
}

.hm-light .hm-markdown th {
  background-color: rgba(55, 65, 72, 0.5);
  color: white;
}

.hm-dark .hm-markdown th {
  background-color: rgba(255, 255, 255, 0.25);
}

.hm-markdown img {
  max-width: 100%;
}

.hm-markdown input[type=checkbox] {
  display: none;
}

.hm-markdown .hm-zoom-container img {
  cursor: zoom-in;
}

.hm-markdown input[type=checkbox]:checked ~ label > img {
  width: 100%;
  cursor: zoom-out;
}

.hm-markdown ol {
  list-style-type: decimal;
}

.hm-markdown ol ol {
  list-style-type: lower-latin;
}

.hm-markdown ol ol ol {
  list-style-type: lower-roman;
}

.hm-markdown ol ol ol ol {
  list-style-type: upper-latin;
}

.hm-markdown ol ol ol ol ol {
  list-style-type: upper-roman;
}

.hm-description-tooltip .hm-markdown a,
.hm-description-tooltip .hm-markdown a:visited
 { 
   color: rgb(155, 192, 255); 
  }
 
.hm-description-tooltip p {
  font-size: 1.25em;
  margin: 0.2em !important;
}

/* Links */
.hm .hm-markdown a {
  text-decoration: none;
  color: #456292;
}

.hm .hm-dark .hm-markdown a {
  color: #FFB300;
}

.hm .hm-dark.hm-dark-blue .hm-markdown a {
  color: #A2BAE4;
}

.hm .hm-markdown a:hover {
  text-decoration: underline;
}
