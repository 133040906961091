.hm .hm-app.container-fluid {
  padding-left: 76px;
  padding-right: 0;
  height: 100%;
}

.hm .hm-light .hm-app.container-fluid {
  background-color: #f7f7f8;
}

.hm .hm-dark .hm-app.container-fluid {
  background-color: #1d252d;
}

.hm .hm-dark .hm-side-nav {
  background-color: black !important;
}

.hm .hm-light .hm-side-nav {
  background-color: rgba(55, 65, 72, 0.1) !important;
}

.hm .hm-sidenav-header {
  background-color: #456292;
}

.hm .hm-dark .hm-sidenav-header {
  background-color: #171a1d;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header {
  background-color: #70a4ff9f;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header path {
  fill: rgba(255, 255, 255, 0.9) !important;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header g > path {
  fill: rgba(255, 255, 255, 0.4) !important;
}

.hm .hm-active-menu-item {
  background-color: #ffffff;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #456292;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon path {
  stroke: #456292;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #456292;
  stroke: transparent;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #456292;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon path,
.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #ffb300;
  stroke: transparent;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon path {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-fill-icon path {
  fill: #70a5ff;
  stroke: transparent;
}

.hm .hm-dark .hm-active-menu-item {
  background-color: #242f38;
}

.hm .hm-side-nav-menu-icon path {
  stroke: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon g {
  stroke: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon {
  fill: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: rgb(51, 63, 72);
  stroke: transparent;
}

.hm .hm-dark .hm-side-nav-menu-icon path {
  stroke: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon g {
  stroke: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon {
  fill: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #ffffff;
  stroke: transparent;
}

.hm .hm-dark a .hm-nav-title {
  color: grey;
}

.hm .hm-light a .hm-nav-title {
  color: grey;
}

.hm .hm-nav-icon {
  text-align: center;
  text-decoration: none;
}

.hm .hm-nav-icon:hover {
  text-decoration: none;
}

.hm .hm-nav-title {
  font-family: 'Lato';
  font-size: 0.6em;
  display: block;
}
/* hivemind theme */
/* Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css */

/* Comment */
.hljs-comment,
.hljs-quote {
  color: #d4d0ab;
}

/* Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: #F77669;
}

/* Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: #F77669;
}

/* Yellow */
.hljs-attr {
  color: #C3E88D;
}

/* Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #ffb300;
}

/* Blue */
.hljs-title,
.hljs-section {
  color: #00e0e0;
}

/* Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #dcc6e0;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #2d3946;
  color: #f8f8f2;
  padding: 0.5em;
}

.hm-dark .hljs {
  background: rgba(138, 138, 138, 0.1); 
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@media screen and (-ms-high-contrast: active) {
  .hljs-addition,
  .hljs-attr,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-bullet,
  .hljs-comment,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-string,
  .hljs-symbol,
  .hljs-type,
  .hljs-quote {
        color: highlight;
    }

    .hljs-keyword,
    .hljs-selector-tag {
        font-weight: bold;
    }
}

 .video-js {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  font-size: 10px;
  /* The main font color changes the ICON COLORS as well as the text */
  color: #fff;
}

/* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/
.hm .hm-video-js .vjs-big-play-button {
  /* The font size is what makes the big play button...big. 
     All width/height values use ems, which are a multiple of the font size.
     If the .video-js font-size is 10px, then 3em equals 30px.*/
  font-size: 8em;
  /* We're using SCSS vars here because the values are used in multiple places.
     Now that font size is set, the following em values will be a multiple of the
     new font size. If the font-size is 3em (30px), then setting any of
     the following values to 3em would equal 30px. 3 * font-size. */
  /* 1.5em = 45px default */
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  /* 0.06666em = 2px default */
  border: 0;
  /* 0.3em = 9px default */
  border-radius: 0.3em;
  /* Align center */
  left: 50%;
  top: 50%;
  margin-left: -1.5em;
  margin-top: -0.75em;
}

.hm .hm-video-js button.vjs-big-play-button,
.hm .hm-video-js .vjs-control-bar button.vjs-button .vjs-icon-placeholder {
  color: #ffffff !important;
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: #2B333F;
  /* Opacity: 1.0 = 100%, 0.0 = 0% */
  background-color: rgba(43, 51, 63, 0.7);
  background-color: transparent;
}

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  height: 6.5px;
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: #fff;
}

/* Enlarged Slider to enable easier tracking. Adjust all the height:6.5px to preferred height for the slider if necessary. */
.video-js .vjs-progress-holder .vjs-load-progress,
.video-js .vjs-progress-holder .vjs-load-progress div,
.video-js .vjs-progress-holder .vjs-play-progress,
.video-js .vjs-progress-holder .vjs-tooltip-progress-bar {
  height: 6.5px;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
  /* For IE8 we'll lighten the color */
  background: ligthen(#73859f, 25%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(115, 133, 159, 0.5);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
  /* For IE8 we'll lighten the color */
  background: ligthen(#73859f, 50%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(115, 133, 159, 0.75);
}

.hm-video-js .vjs-poster {
  outline: none;
  /* Remove Blue Outline on Click*/
  outline: 0;
}

.hm-video-js:hover .vjs-big-play-button {
  background-color: transparent;
}

.hm-video-js .vjs-fullscreen-control:before,
.hm-video-js.vjs-fullscreen .vjs-fullscreen-control:before {
  content: '';
  /* Remove Fullscreen Exit Icon */
}

.hm-video-js.vjs-fullscreen .vjs-fullscreen-control {
  background: #fff;
}

.hm-video-js .vjs-fullscreen-control {
  border: 3px solid #fff;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: -7px;
  top: 50%;
  height: 14px;
  width: 22px;
  margin-right: 10px;
}

.hm-video-js.vjs-fullscreen .vjs-fullscreen-control:after {
  background: #000;
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 5px;
}

.hm-video-js .vjs-progress-holder {
  margin: 0;
}

.hm-video-js .vjs-progress-control .vjs-progress-holder:after {
  border-radius: 2px;
  display: block;
  height: 6.5px;
}

.hm-video-js .vjs-progress-control .vjs-load-progres,
.hm-video-js .vjs-progress-control .vjs-play-progress {
  border-radius: 2px;
  height: 6.5px;
}

.hm-video-js .vjs-playback-rate {
  display: none;
  /* Remove Playback Rate */
}

.hm-video-js .vjs-progress-control {
  margin-right: 50px;
}

.hm-video-js .vjs-time-control {
  right: 55px;
}

.hm-video-js .vjs-volume-menu-button:before {
  width: 1.2em;
  z-index: 1;
}

.hm-video-js .vjs-volume-menu-button .vjs-menu,
.hm-video-js .vjs-volume-menu-button:focus .vjs-menu,
.hm-video-js .vjs-volume-menu-button.vjs-slider-active .vjs-menu {
  display: block;
  opacity: 1;
}

.hm-video-js .vjs-volume-menu-button,
.hm-video-js .vjs-volume-panel {
  width: 6em;
  position: absolute;
  right: 0;
  margin-right: 30px;
}

.hm-video-js .vjs-volume-menu-button .vjs-menu-content,
.hm-video-js .vjs-volume-menu-button:hover,
.hm-video-js .vjs-volume-menu-button:focus,
.hm-video-js .vjs-volume-menu-button.vjs-slider-active,
.hm-video-js .vjs-volume-panel .vjs-volume-control,
.hm-video-js .vjs-volume-panel:hover,
.hm-video-js .vjs-volume-panel:focus,
.hm-video-js .vjs-volume-panel.vjs-slider-active {
  width: 6em;
}

.hm-video-js .vjs-volume-menu-button .vjs-menu {
  left: 23px;
}

.hm-video-js .vjs-mouse-display:before,
.hm-video-js .vjs-play-progress:before,
.hm-video-js .vjs-volume-level:before {
  content: '';
  /* Remove Circle From Progress Bar */
}

.hm-video-js .vjs-mouse-display:after,
.hm-video-js .vjs-play-progress:after,
.hm-video-js .vjs-time-tooltip {
  width: 5.5em;
}

.hm-video-js .vjs-audio-button {
  display: none;
}

.hm-video-js .vjs-volume-bar {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAcCAQAAACw95UnAAAAMElEQVRIx2NgoBL4n4YKGUYNHkEG4zJg1OCRYDCpBowaPJwMppbLRg0eNXjUYBLEAXWNUA6QNm1lAAAAAElFTkSuQmCC);
  background-size: 22px 14px;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  max-width: 22px;
  max-height: 14px;
  margin: 7px 4px;
  border-radius: 0;
}

.hm-video-js .vjs-volume-level {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAcAQAAAAAyhWABAAAAAnRSTlMAAHaTzTgAAAAZSURBVHgBYwAB/g9EUv+JokCqiaT+U4MCAPKPS7WUUOc1AAAAAElFTkSuQmCC);
  background-size: 22px 14px;
  background-repeat: no-repeat;
  max-width: 22px;
  max-height: 14px;
  height: 100%;
}

/* New for VideoJS v6 */
.hm-video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active,
.hm-video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
.hm-video-js .vjs-volume-panel.vjs-volume-panel-horizontal:focus,
.hm-video-js .vjs-volume-panel.vjs-volume-panel-horizontal:hover {
  width: 6em;
  transition-property: none;
}

.hm-video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-horizontal {
  width: 3em;
  height: auto;
}

.hm-video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control {
  transition-property: none;
}

.hm-video-js .vjs-fullscreen-control .vjs-icon-placeholder {
  display: none;
  /* Remove Duplicate Fullscreen Icon */
}

.hm-video-js .vjs-volume-panel .vjs-mute-control {
  width: 2em;
  z-index: 1;
  padding: 0;
}

.hm-video-js .vjs-volume-panel .vjs-volume-control {
  display: inline-block;
  position: relative;
  left: 5px;
  opacity: 1;
  width: 3em;
  height: auto;
} 

.hm-markdown table {
  margin-top: 1.5em;
  border-collapse: collapse;
  width: 100%;
}

.hm-markdown  th, td {
  text-align: left;
  padding: 0.9em;
}

.hm-light .hm-markdown tr:nth-child(even){
  background-color: rgba(55, 65, 72, 0.1)
}

.hm-dark .hm-markdown tr:nth-child(even){
  background-color: rgba(255, 255, 255, 0.1)
}

.hm-light .hm-markdown th {
  background-color: rgba(55, 65, 72, 0.5);
  color: white;
}

.hm-dark .hm-markdown th {
  background-color: rgba(255, 255, 255, 0.25);
}

.hm-markdown img {
  max-width: 100%;
}

.hm-markdown input[type=checkbox] {
  display: none;
}

.hm-markdown .hm-zoom-container img {
  cursor: zoom-in;
}

.hm-markdown input[type=checkbox]:checked ~ label > img {
  width: 100%;
  cursor: zoom-out;
}

.hm-markdown ol {
  list-style-type: decimal;
}

.hm-markdown ol ol {
  list-style-type: lower-latin;
}

.hm-markdown ol ol ol {
  list-style-type: lower-roman;
}

.hm-markdown ol ol ol ol {
  list-style-type: upper-latin;
}

.hm-markdown ol ol ol ol ol {
  list-style-type: upper-roman;
}

.hm-description-tooltip .hm-markdown a,
.hm-description-tooltip .hm-markdown a:visited
 { 
   color: rgb(155, 192, 255); 
  }
 
.hm-description-tooltip p {
  font-size: 1.25em;
  margin: 0.2em !important;
}

/* Links */
.hm .hm-markdown a {
  text-decoration: none;
  color: #456292;
}

.hm .hm-dark .hm-markdown a {
  color: #FFB300;
}

.hm .hm-dark.hm-dark-blue .hm-markdown a {
  color: #A2BAE4;
}

.hm .hm-markdown a:hover {
  text-decoration: underline;
}

.hm .hm-form-holder-image {
  background-size: cover !important;
  width: 100%;
  height: 100vh;
  background-position: right top;
}

@media only screen and (max-width: 1000px) {
  .hm .hm-form-holder-image {
    display: none;
  }
}

.hm .hm-form-holder svg mask rect {
  fill: white;
}

.hm .hm-form-holder svg > rect {
  fill: white;
  -webkit-mask: url(#hm-mask);
  mask: url(#hm-mask);
}

.hm .hm-dark .hm-form-holder svg > rect {
  fill: #1d252d;
}

.hm .hm-form-holder svg .hm-logo-title {
  text-anchor: middle;
  fill: white;
}

.hm .hm-form-holder svg .hm-logo-small-title {
  text-anchor: middle;
  fill: white;
}

/* Disables yellow highlighting of autofill inputs in webkit browsers */
.hm .hm-light input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px white;
}

.hm .hm-dark input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px #1d252d;
  -webkit-text-fill-color: #ffffff !important;
}

.hm .hm-form-holder {
  overflow: hidden;
}

.hm .hm-light .hm-form-holder {
  background-color: #ffffff;
}

.hm .hm-dark .hm-form-holder {
  background-color: #1d252d;
}
/* Uncomment and set these variables to customize the grid. */

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  
  .row.reverse {
    flex-direction: row-reverse;
  }
  
  .col.reverse {
    flex-direction: column-reverse;
  }
  
  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  .col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-xs-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  
  .col-xs-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  
  .col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  
  .col-xs-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  
  .col-xs-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  
  .col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  
  .col-xs-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  
  .col-xs-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  
  .col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  
  .col-xs-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  
  .col-xs-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  
  .col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  
  .col-xs-offset-1 {
    margin-left: 8.333%;
  }
  
  .col-xs-offset-2 {
    margin-left: 16.667%;
  }
  
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  
  .col-xs-offset-4 {
    margin-left: 33.333%;
  }
  
  .col-xs-offset-5 {
    margin-left: 41.667%;
  }
  
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  
  .col-xs-offset-7 {
    margin-left: 58.333%;
  }
  
  .col-xs-offset-8 {
    margin-left: 66.667%;
  }
  
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  
  .col-xs-offset-10 {
    margin-left: 83.333%;
  }
  
  .col-xs-offset-11 {
    margin-left: 91.667%;
  }
  
  .start-xs {
    justify-content: flex-start;
    text-align: start;
  }
  
  .center-xs {
    justify-content: center;
    text-align: center;
  }
  
  .end-xs {
    justify-content: flex-end;
    text-align: end;
  }
  
  .top-xs {
    align-items: flex-start;
  }
  
  .middle-xs {
    align-items: center;
  }
  
  .bottom-xs {
    align-items: flex-end;
  }
  
  .around-xs {
    justify-content: space-around;
  }
  
  .between-xs {
    justify-content: space-between;
  }
  
  .first-xs {
    order: -1;
  }
  
  .last-xs {
    order: 1;
  }
  
  @media only screen and (min-width: 48em) {
    .container {
      width: 46rem;
    }
  
    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  
    .col-sm {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
  
    .col-sm-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }
  
    .col-sm-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }
  
    .col-sm-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
  
    .col-sm-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }
  
    .col-sm-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }
  
    .col-sm-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
  
    .col-sm-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }
  
    .col-sm-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }
  
    .col-sm-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
  
    .col-sm-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }
  
    .col-sm-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }
  
    .col-sm-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  
    .col-sm-offset-1 {
      margin-left: 8.333%;
    }
  
    .col-sm-offset-2 {
      margin-left: 16.667%;
    }
  
    .col-sm-offset-3 {
      margin-left: 25%;
    }
  
    .col-sm-offset-4 {
      margin-left: 33.333%;
    }
  
    .col-sm-offset-5 {
      margin-left: 41.667%;
    }
  
    .col-sm-offset-6 {
      margin-left: 50%;
    }
  
    .col-sm-offset-7 {
      margin-left: 58.333%;
    }
  
    .col-sm-offset-8 {
      margin-left: 66.667%;
    }
  
    .col-sm-offset-9 {
      margin-left: 75%;
    }
  
    .col-sm-offset-10 {
      margin-left: 83.333%;
    }
  
    .col-sm-offset-11 {
      margin-left: 91.667%;
    }
  
    .start-sm {
      justify-content: flex-start;
      text-align: start;
    }
  
    .center-sm {
      justify-content: center;
      text-align: center;
    }
  
    .end-sm {
      justify-content: flex-end;
      text-align: end;
    }
  
    .top-sm {
      align-items: flex-start;
    }
  
    .middle-sm {
      align-items: center;
    }
  
    .bottom-sm {
      align-items: flex-end;
    }
  
    .around-sm {
      justify-content: space-around;
    }
  
    .between-sm {
      justify-content: space-between;
    }
  
    .first-sm {
      order: -1;
    }
  
    .last-sm {
      order: 1;
    }
  }
  
  @media only screen and (min-width: 62em) {
    .container {
      width: 61rem;
    }
  
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  
    .col-md {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
  
    .col-md-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }
  
    .col-md-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }
  
    .col-md-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
  
    .col-md-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }
  
    .col-md-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }
  
    .col-md-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
  
    .col-md-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }
  
    .col-md-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }
  
    .col-md-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
  
    .col-md-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }
  
    .col-md-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }
  
    .col-md-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  
    .col-md-offset-1 {
      margin-left: 8.333%;
    }
  
    .col-md-offset-2 {
      margin-left: 16.667%;
    }
  
    .col-md-offset-3 {
      margin-left: 25%;
    }
  
    .col-md-offset-4 {
      margin-left: 33.333%;
    }
  
    .col-md-offset-5 {
      margin-left: 41.667%;
    }
  
    .col-md-offset-6 {
      margin-left: 50%;
    }
  
    .col-md-offset-7 {
      margin-left: 58.333%;
    }
  
    .col-md-offset-8 {
      margin-left: 66.667%;
    }
  
    .col-md-offset-9 {
      margin-left: 75%;
    }
  
    .col-md-offset-10 {
      margin-left: 83.333%;
    }
  
    .col-md-offset-11 {
      margin-left: 91.667%;
    }
  
    .start-md {
      justify-content: flex-start;
      text-align: start;
    }
  
    .center-md {
      justify-content: center;
      text-align: center;
    }
  
    .end-md {
      justify-content: flex-end;
      text-align: end;
    }
  
    .top-md {
      align-items: flex-start;
    }
  
    .middle-md {
      align-items: center;
    }
  
    .bottom-md {
      align-items: flex-end;
    }
  
    .around-md {
      justify-content: space-around;
    }
  
    .between-md {
      justify-content: space-between;
    }
  
    .first-md {
      order: -1;
    }
  
    .last-md {
      order: 1;
    }
  }
  
  @media only screen and (min-width: 94em) {
    .container {
      width: 71rem;
    }
  
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  
    .col-lg {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
  
    .col-lg-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }
  
    .col-lg-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }
  
    .col-lg-3 {
      flex-basis: 25%;
      max-width: 25%;
    }
  
    .col-lg-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }
  
    .col-lg-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }
  
    .col-lg-6 {
      flex-basis: 50%;
      max-width: 50%;
    }
  
    .col-lg-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }
  
    .col-lg-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }
  
    .col-lg-9 {
      flex-basis: 75%;
      max-width: 75%;
    }
  
    .col-lg-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }
  
    .col-lg-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }
  
    .col-lg-12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  
    .col-lg-offset-1 {
      margin-left: 8.333%;
    }
  
    .col-lg-offset-2 {
      margin-left: 16.667%;
    }
  
    .col-lg-offset-3 {
      margin-left: 25%;
    }
  
    .col-lg-offset-4 {
      margin-left: 33.333%;
    }
  
    .col-lg-offset-5 {
      margin-left: 41.667%;
    }
  
    .col-lg-offset-6 {
      margin-left: 50%;
    }
  
    .col-lg-offset-7 {
      margin-left: 58.333%;
    }
  
    .col-lg-offset-8 {
      margin-left: 66.667%;
    }
  
    .col-lg-offset-9 {
      margin-left: 75%;
    }
  
    .col-lg-offset-10 {
      margin-left: 83.333%;
    }
  
    .col-lg-offset-11 {
      margin-left: 91.667%;
    }
  
    .start-lg {
      justify-content: flex-start;
      text-align: start;
    }
  
    .center-lg {
      justify-content: center;
      text-align: center;
    }
  
    .end-lg {
      justify-content: flex-end;
      text-align: end;
    }
  
    .top-lg {
      align-items: flex-start;
    }
  
    .middle-lg {
      align-items: center;
    }
  
    .bottom-lg {
      align-items: flex-end;
    }
  
    .around-lg {
      justify-content: space-around;
    }
  
    .between-lg {
      justify-content: space-between;
    }
  
    .first-lg {
      order: -1;
    }
  
    .last-lg {
      order: 1;
    }
  }
/* Fonts */

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  src: url(/static/media/lato-light.82bc31aa.woff2) format('woff2'),
    url(/static/media/lato-light.ad990910.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/lato-regular.3ceb09f6.woff2) format('woff2'),
    url(/static/media/lato-regular.453ee82f.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url(/static/media/lato-regular-italic.895d0b33.woff2) format('woff2'),
    url(/static/media/lato-regular-italic.22f9bdf3.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url(/static/media/lato-bold.b68c39cb.woff2) format('woff2'),
    url(/static/media/lato-bold.bf047072.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: italic;
  src: url(/static/media/lato-bold-italic.ecce01cb.woff2) format('woff2'),
    url(/static/media/lato-bold-italic.4ad27904.woff) format('woff');
}

@font-face {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/josefinsans-semibold.af86d657.woff2) format('woff2'),
    url(/static/media/josefinsans-semibold.467a436c.woff) format('woff');
}

/* App */

.hm {
  font-size: 16px;
  color: #000;
  font-family: 'Lato', sans-serif;
}

.hm .hm-dark {
  color: #ffffff;
}

.hm .hm-logo-title {
  color: white;
  font-size: 3em;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
}

.hm .hm-logo-small-title {
  color: white;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 2.1px;
}

/* Layout */
.hm .hm-no-padding {
  padding: 0;
}

.hm .hm-no-margin {
  margin: 0;
}
.hm .hm-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hm .hm-aligner-item {
  flex: 1 1;
}

.hm .hm-aligner-item-top {
  align-self: flex-start;
}

.hm .hm-aligner-item-bottom {
  align-self: flex-end;
}

.hm .hm-aligner-item-fixed {
  flex: none;
  max-width: 50%;
}

.hm .row {
  margin-left: 0;
  margin-right: 0;
}

/* Text */

.hm h1 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.7em;
  margin: 0;
}

.hm .hm-dark h1 {
  color: #ffffff;
}

.hm h2 {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1.15em;
  line-height: 1.5em;
  margin: 0;
}

.hm .hm-dark h2 {
  color: #ffffff;
}

.hm .hm-subtitle {
  font-family: 'Lato';
  font-size: 0.875em;
  line-height: 1.14;
}

.hm .hm-dark .hm-subtitle {
  color: rgba(255, 255, 255, 0.4);
}

.hm .hm-link-title {
  font-family: 'Lato';
  font-size: 1em;
}

.hm .hm-light .hm-link-title {
  color: #333f48;
}

.hm .hm-dark .hm-link-title {
  color: #ffb300;
}

.hm .hm-dark.hm-dark-blue .hm-link-title {
  color: #a2bae4;
}

.hm .hm-link-subtitle {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 0.625em;
  line-height: 1.6;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

.hm .hm-light .hm-link-subtitle {
  color: #456292;
}

.hm .hm-dark .hm-link-subtitle {
  opacity: 0.5;
  color: #ffffff;
}

.hm .hm-card-title {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 1.1875em;
  letter-spacing: 0.11875em;
  text-align: center;
  text-transform: uppercase;
}

.hm .hm-dark .hm-card-title {
  color: #ffffff;
}

.hm .hm-card-subtitle {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 0.75em;
  letter-spacing: 0.06316em;
  line-height: 1.33;
  text-align: center;
  text-transform: uppercase;
}

.hm .hm-dark .hm-card-subtitle {
  color: #ffffff;
  opacity: 0.5;
}

.hm .hm-card-figure {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 2em;
  text-align: center;
}

.hm .hm-dark .hm-card-figure {
  color: #ffffff;
}

.hm .hm-card-body {
  font-family: 'Lato';
  font-size: 0.875em;
  line-height: 1.14;
}

.hm .hm-dark .hm-card-body {
  color: #ffffff;
  opacity: 0.4;
}

.hm .hm-flex-column-container,
.hm .hm-tabs,
.hm .hm-tab-content,
.hm .hm-tab-content > div,
.hm .hm-tab-content > div > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* For users with motion issues or devices that struggle with animations */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  /* Remove duration for all unknown animation when a user requests a reduced animation experience */
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
.agora_aligner {
  flex-direction: column;
  align-items: stretch;
  justify-content: normal;
}

.agora_form_label {
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  color: #8ea0b3;
  padding-bottom: 5px;
}

.hm-form-holder-image {
  background: url('/bg.jpg');
}

.hm .hm-app {
  color: #ffffff;
  background-color: #1d252d;
}

.hm .hm-split-screen {
  padding: 1.5em;
  min-height: 100vh;
}

@media (max-width: 94em) {
  .hm .hm-split-screen {
    padding: 0.5em;
  }
}

.hm .hm-split-left {
  background-color: #1d252d;
}

.hm .CodeMirror {
  height: 200px;
}

.hm .hm-split-right {
  background-color: #333f48;
}

.hm .hm-split-right.hm-no-side-padding, .hm .hm-split-left.hm-no-side-padding, .hm .hm-full-screen.hm-no-side-padding {
  padding: 1em 0 0 0;
}

.hm .hm-full-screen {
  display: flex;
  flex-grow: 1;
}

.hm button[disabled] svg {
  fill: rgba(255, 255, 255, 0.2) !important;
}

.hm button[disabled] {
  background-color: transparent !important;
}

.hm button.hm-button[disabled] {
  background-color: rgba(138, 138, 138, 0.6) !important;
}

.hm input::-ms-clear {
  display: none;
}

.hm-tabbed-right-hand-panel .hm-tab-content {
  padding: 2em 0 0 0;
  flex-grow: 1;
  display: flex;
}

.hm-tabbed-right-hand-panel .hm-tab-content>div {
  flex-grow: 1;
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.hm-tabbed-right-hand-panel .hm-tab-content>div>div {
  flex-grow: 1;
  display: flex;
}

.hm-tabbed-right-hand-panel {
  display: flex;
  flex-grow: 1;
}

/* TODO: move below to common */

.hm-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.hm div.hm-pane-group-appear {
  opacity: 0;
  transform: translate(0px, 25px) scale(0.95);
}

.hm div.hm-pane-group-appear.hm-pane-group-appear-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: all 0.5s ease-out;
}

.hm .hm-left-pane h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hm .hm-left-pane {
  width: 100%;
}

.hm-result-display .CodeMirror, .hm-result-display .react-codemirror2, .hm-result-display .CodeMirror-scroll {
  flex-grow: 1 !important;
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
}

.hm .hm-card-figure.hm-card-figure-small {
  font-size: 1.6em;
}

.hm .hm-dark .hm-tab-headers a:not(.active) .hm-badge {
  background: rgba(255, 255, 255, 0.4);
}

.hm .hm-dark .hm-badge span {
  color: #333f48;
}

.hm .hm-table .hm-loading-row {
  cursor: auto;
}

.hm .hm-button.hm-tiny>div>svg {
  width: 0.8em !important;
  height: 0.8em !important;
}

.hm .hm-table .hm-table-header-row {
  line-height: 1.14;
}

.hm .hm-table td, .hm .hm-table th {
  padding: 12.6px 7px;
}

.hm .hm-centered input {
  text-align: center;
}

.hm h1 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 1.7em;
  line-height: 1.7em;
  margin: 0;
}
/* Disables yellow highlighting of autofill inputs in webkit browsers */

.hm-light input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px white;
}

.hm-dark input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px #1d252d;
  -webkit-text-fill-color: #ffffff !important;
}

.hm-form-holder {
  overflow: hidden;
}

.hm-light .hm-form-holder {
  background-color: #ffffff;
}

.hm-dark .hm-form-holder {
  background-color: #1d252d;
}

.agora_header_container {
  box-shadow: 0 0.5px 0 0 #36414d;
  padding: 35px 0px;
}

.agora_header_container div {
  display: flex;
  padding: 0;
}

.agora_header_container-children {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
}
.hm-login
{
    color: #456292;
    font-size: 13.5px;
    font-weight: 500;
}

.hm-dark .hm-login{
    color: #8ea0b3;
}

.agora_login_form-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20%;
    text-align: center;
    font-weight: 300;
    font-size: 22px;
    -webkit-user-select: none;
            user-select: none;
}
