.agora_aligner {
  flex-direction: column;
  align-items: stretch;
  justify-content: normal;
}

.agora_form_label {
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  color: #8ea0b3;
  padding-bottom: 5px;
}

.hm-form-holder-image {
  background: url('/bg.jpg');
}

.hm .hm-app {
  color: #ffffff;
  background-color: #1d252d;
}

.hm .hm-split-screen {
  padding: 1.5em;
  min-height: 100vh;
}

@media (max-width: 94em) {
  .hm .hm-split-screen {
    padding: 0.5em;
  }
}

.hm .hm-split-left {
  background-color: #1d252d;
}

.hm .CodeMirror {
  height: 200px;
}

.hm .hm-split-right {
  background-color: #333f48;
}

.hm .hm-split-right.hm-no-side-padding, .hm .hm-split-left.hm-no-side-padding, .hm .hm-full-screen.hm-no-side-padding {
  padding: 1em 0 0 0;
}

.hm .hm-full-screen {
  display: flex;
  flex-grow: 1;
}

.hm button[disabled] svg {
  fill: rgba(255, 255, 255, 0.2) !important;
}

.hm button[disabled] {
  background-color: transparent !important;
}

.hm button.hm-button[disabled] {
  background-color: rgba(138, 138, 138, 0.6) !important;
}

.hm input::-ms-clear {
  display: none;
}

.hm-tabbed-right-hand-panel .hm-tab-content {
  padding: 2em 0 0 0;
  flex-grow: 1;
  display: flex;
}

.hm-tabbed-right-hand-panel .hm-tab-content>div {
  flex-grow: 1;
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.hm-tabbed-right-hand-panel .hm-tab-content>div>div {
  flex-grow: 1;
  display: flex;
}

.hm-tabbed-right-hand-panel {
  display: flex;
  flex-grow: 1;
}

/* TODO: move below to common */

.hm-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.hm div.hm-pane-group-appear {
  opacity: 0;
  transform: translate(0px, 25px) scale(0.95);
}

.hm div.hm-pane-group-appear.hm-pane-group-appear-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: all 0.5s ease-out;
}

.hm .hm-left-pane h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hm .hm-left-pane {
  width: 100%;
}

.hm-result-display .CodeMirror, .hm-result-display .react-codemirror2, .hm-result-display .CodeMirror-scroll {
  flex-grow: 1 !important;
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
}

.hm .hm-card-figure.hm-card-figure-small {
  font-size: 1.6em;
}

.hm .hm-dark .hm-tab-headers a:not(.active) .hm-badge {
  background: rgba(255, 255, 255, 0.4);
}

.hm .hm-dark .hm-badge span {
  color: #333f48;
}

.hm .hm-table .hm-loading-row {
  cursor: auto;
}

.hm .hm-button.hm-tiny>div>svg {
  width: 0.8em !important;
  height: 0.8em !important;
}

.hm .hm-table .hm-table-header-row {
  line-height: 1.14;
}

.hm .hm-table td, .hm .hm-table th {
  padding: 12.6px 7px;
}

.hm .hm-centered input {
  text-align: center;
}

.hm h1 {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 1.7em;
  line-height: 1.7em;
  margin: 0;
}