.hm-login
{
    color: #456292;
    font-size: 13.5px;
    font-weight: 500;
}

.hm-dark .hm-login{
    color: #8ea0b3;
}

.agora_login_form-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20%;
    text-align: center;
    font-weight: 300;
    font-size: 22px;
    user-select: none;
}