.agora_header_container {
  box-shadow: 0 0.5px 0 0 #36414d;
  padding: 35px 0px;
}

.agora_header_container div {
  display: flex;
  padding: 0;
}

.agora_header_container-children {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
}