.hm .hm-dark .hm-side-nav {
  background-color: black !important;
}

.hm .hm-light .hm-side-nav {
  background-color: rgba(55, 65, 72, 0.1) !important;
}

.hm .hm-sidenav-header {
  background-color: #456292;
}

.hm .hm-dark .hm-sidenav-header {
  background-color: #171a1d;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header {
  background-color: #70a4ff9f;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header path {
  fill: rgba(255, 255, 255, 0.9) !important;
}

.hm .hm-dark.hm-dark-blue .hm-sidenav-header g > path {
  fill: rgba(255, 255, 255, 0.4) !important;
}

.hm .hm-active-menu-item {
  background-color: #ffffff;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #456292;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon path {
  stroke: #456292;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #456292;
  stroke: transparent;
}

.hm .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #456292;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon path,
.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #ffb300;
}

.hm .hm-dark .hm-active-menu-item .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #ffb300;
  stroke: transparent;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon g {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon path {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-side-nav-menu-icon {
  stroke: #70a5ff;
}

.hm .hm-dark.hm-dark-blue .hm-active-menu-item .hm-fill-icon path {
  fill: #70a5ff;
  stroke: transparent;
}

.hm .hm-dark .hm-active-menu-item {
  background-color: #242f38;
}

.hm .hm-side-nav-menu-icon path {
  stroke: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon g {
  stroke: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon {
  fill: rgb(51, 63, 72);
}

.hm .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: rgb(51, 63, 72);
  stroke: transparent;
}

.hm .hm-dark .hm-side-nav-menu-icon path {
  stroke: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon g {
  stroke: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon {
  fill: #ffffff;
}

.hm .hm-dark .hm-side-nav-menu-icon.hm-fill-icon path {
  fill: #ffffff;
  stroke: transparent;
}

.hm .hm-dark a .hm-nav-title {
  color: grey;
}

.hm .hm-light a .hm-nav-title {
  color: grey;
}

.hm .hm-nav-icon {
  text-align: center;
  text-decoration: none;
}

.hm .hm-nav-icon:hover {
  text-decoration: none;
}

.hm .hm-nav-title {
  font-family: 'Lato';
  font-size: 0.6em;
  display: block;
}