.hm .hm-app.container-fluid {
  padding-left: 76px;
  padding-right: 0;
  height: 100%;
}

.hm .hm-light .hm-app.container-fluid {
  background-color: #f7f7f8;
}

.hm .hm-dark .hm-app.container-fluid {
  background-color: #1d252d;
}
