.hm .hm-form-holder-image {
  background-size: cover !important;
  width: 100%;
  height: 100vh;
  background-position: right top;
}

@media only screen and (max-width: 1000px) {
  .hm .hm-form-holder-image {
    display: none;
  }
}

.hm .hm-form-holder svg mask rect {
  fill: white;
}

.hm .hm-form-holder svg > rect {
  fill: white;
  -webkit-mask: url(#hm-mask);
  mask: url(#hm-mask);
}

.hm .hm-dark .hm-form-holder svg > rect {
  fill: #1d252d;
}

.hm .hm-form-holder svg .hm-logo-title {
  text-anchor: middle;
  fill: white;
}

.hm .hm-form-holder svg .hm-logo-small-title {
  text-anchor: middle;
  fill: white;
}
